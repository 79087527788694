import React, { useState } from 'react';

import './App.css';

import VibrantCodeValidator from './components/VibrantCodeValidator';
import EmailVerificationForm from './components/UserLogin';


function App() {
  const [isValidated, setIsValidated] = useState(true);
  return (
    <>
      {!isValidated ? (
        <EmailVerificationForm {...{ setIsValidated }} />
      ) : (
        <VibrantCodeValidator />
      )}
    </>
  );
}

export default App;
