import React, { useState } from 'react';
import LatamexLogo from '../assets/latamex-logo.svg';


const VibrantCodeValidator = () => {
    const [errors, setErrors] = useState({});
    const [dni, setDni] = useState('');
    const [email, setEmail] = useState('');
    const [orderId, setOrderId] = useState('');
    const [comprobante, setComprobante] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [responseCode, setResponseCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [customErrorMessage, setCustomErrorMessage] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleDniChange = (e) => {
        setDni(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, dni: '' }));
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    };

    const handleOrderIdChange = (e) => {
        setOrderId(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, orderId: '' }));
    };

    const handleComprobanteChange = (e) => {
        setComprobante(e.target.files[0]);
        e.target && validarComprobante(e.target.files[0]);
        setErrors((prevErrors) => ({ ...prevErrors, comprobante: '' }));
    };

    const validarComprobante = (file) => {
        const allowedTypes = ['application/pdf'];
        if (!allowedTypes.includes(file?.type)) {
            setErrors((prevErrors) => ({ ...prevErrors, comprobanteType: 'El archivo seleccionado no es un PDF válido.' }));
            return 'El archivo seleccionado no es un PDF válido.';
        }
        if (file?.size > 10000000) {
            setErrors((prevErrors) => ({ ...prevErrors, comprobanteType: 'El archivo seleccionado es demasiado grande. Por favor, intenta con uno más pequeño.' }));
            return 'El archivo seleccionado es demasiado grande. Por favor, intenta con uno más pequeño.';
        }
        setErrors((prevErrors) => ({ ...prevErrors, comprobanteType: '' }));
        return true;
    }

    const numberSanitize = (input) => {
        return input.replace(/\D/g, '');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formErrors = {};

        if (!regex.test(email)) {
            formErrors.email = 'Verifica tu email';
        }

        if (!numberSanitize(dni) || numberSanitize(dni).length < 7) {
            formErrors.dni = 'Revisa tu DNI';
        }

        if (!numberSanitize(orderId) || numberSanitize(orderId).length < 4) {
            formErrors.orderId = 'Ingresa tu número de orden';
        }

        if (!comprobante) {
            formErrors.comprobante = 'Adjunta tu comprobante';
        }

        if (comprobante && validarComprobante(comprobante) !== true) {
            formErrors.comprobante = validarComprobante(comprobante);
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('email', email);
            formData.append('nationalId', numberSanitize(dni));
            formData.append('orderId', numberSanitize(orderId));
            formData.append('receipt', comprobante);

            try {
                await fetch(`${API_BASE_URL}sep06/bank-receipt`, {
                    method: 'PUT',
                    body: formData,
                })
                    .then(response => {
                        if (response.status === 413) {
                            setCustomErrorMessage('El archivo seleccionado es demasiado grande. Por favor, intenta con uno más pequeño.');
                        }
                        if (!response.ok) {
                            setError(true);
                            throw new Error(`Error en la solicitud: ${response.statusText}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data && (data.done || data.code === 'P200' || data.code === 'P204')) {
                            setResponseCode(data.code);
                            setSuccess(true);
                        }
                        else
                            setError(true);
                    })
                    .catch(error => {
                        console.error('Error en la solicitud:', error);
                        setError(true);
                    });

            } catch (error) {
                console.error('Error:', error);
                setError(true);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCloseTab = () => {
        window.close();
    }


    const handleClose = () => {
        setSuccess(false);
        setError(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-white sm:bg-gray-200">
            {!success && (
                <div className="flex flex-col justify-center max-w-screen w-full space-y-0 min-h-screen w-full min-h-screen items-center sm:min-h-fit sm:max-w-fit sm:min-w-fit">
                    <img className='mb-5 block' src={LatamexLogo} width="200"></img>
                    <form className="flex flex-col max-w-screen p-6 bg-white min-w-full rounded sm:shadow-lg sm:min-w-[24rem] sm:min-h-fit ">


                        <div className='flex justify-center flex-col'>
                            <div className="mb-4">
                                <label htmlFor="dni" className="block mb-2 font-bold text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    className={`w-full focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-200 p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    placeholder="Ingresa tu email de registro de Vibrant"
                                    disabled={isLoading}
                                />
                                {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="dni" className="block mb-2 font-bold text-gray-700">
                                    DNI
                                </label>
                                <input
                                    type="text"
                                    id="dni"
                                    name="dni"
                                    value={dni}
                                    maxLength={20}
                                    onChange={handleDniChange}
                                    className={`w-full focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-200 p-2 border ${errors.dni ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    placeholder="Ingresa tu DNI"
                                    disabled={isLoading}
                                />
                                {errors.dni && <p className="mt-1 text-sm text-red-500">{errors.dni}</p>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="orderId" className="block mb-2 font-bold text-gray-700">
                                    Código de Referencia / Número de Orden
                                </label>
                                <input
                                    type="number"
                                    id="orderId"
                                    name="orderId"
                                    value={orderId}
                                    onChange={handleOrderIdChange}
                                    className={`w-full focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-200 p-2 border ${errors.orderId ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    placeholder="Ingresa tu número de orden"
                                    disabled={isLoading}
                                />
                                {errors.orderId && <p className="mt-1 text-sm text-red-500">{errors.orderId}</p>}
                                <p className="mt-1 text-sm text-gray-700">Ingresá tu número de orden de 5 dígitos que<br />
                                    se encuentra en el correo electrónico que recibiste<br />
                                    de vibrant@latamex.com al crear la orden</p>

                            </div>

                            <div className="mb-4">
                                <label htmlFor="comprobante" className="block mb-2 font-bold text-gray-700">
                                    Comprobante <small className='font-normal'>(Max. 10MB)</small>
                                </label>
                                <label htmlFor="comprobante" className="flex items-center justify-center hover:bg-gray-100 py-2 px-4 border-dashed border border-gray-300 rounded cursor-pointer w-full">
                                    <svg class="w-3 h-3 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12V1m0 0L4 5m4-4 4 4m3 5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                    </svg>
                                    <span className='text-black ml-2 text-sm font-semibold'>
                                        Buscar documento
                                    </span>
                                </label>
                                <input
                                    type="file"
                                    id="comprobante"
                                    name="comprobante"
                                    onChange={handleComprobanteChange}
                                    className={`hidden w-full p-2 border ${errors.comprobante || error.comprobanteType ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    disabled={isLoading}
                                />
                                {comprobante && <p className="text-gray-700 text-xs inline-block whitespace-nowrap overflow-hidden text-ellipsis" style={{ 'maxWidth': '20rem' }}>Documento: {comprobante.name}</p>}
                                {(errors.comprobante || errors.comprobanteType) && <p className="mt-1 text-sm text-red-500">{errors.comprobante}{errors.comprobanteType}</p>}
                            </div>
                            <div className="mb-4">
                                <p className="mt-1 text-sm text-black-900 font-bold text-decoration-line: underline">
                                    <a href='https://vimeo.com/911705784' target='_blank'>¿Cómo encontrás el comprobante?</a>
                                </p>
                            </div>

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="w-full px-4 py-2 text-white font-bold flex justify-center items-center rounded bg-latamex-green-500 hover:bg-latamex-green-600"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ?
                                        <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg> : 'Enviar'}
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            )}
            {success && (
                <div className="flex flex items-center justify-center flex-col max-w-screen w-full min-h-screen w-full min-h-screen p-6 bg-white rounded shadow-lg sm:min-h-fit sm:max-w-fit sm:min-w-fit">
                    <svg class="w-20 h-30 text-latamex-green-500 dark:text-latamex-green mb-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M6 1v4a1 1 0 0 1-1 1H1m4 6 2 2 4-4m4-8v16a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2Z" />
                    </svg>
                    <p className="text-lg font-bold mb-2">Gracias!</p>
                    <p>{responseCode === 'P204' ? ('Tu orden ya fue procesada') : ('Ya recibimos la información')}</p>
                    <p>Ahora puedes cerrar esta ventana.<br /><br /></p>
                    <p className='inline-block align-middle'>El equipo de <img className='inline-block' src={LatamexLogo} width="85"></img></p>

                    {/* <button className="mt-4 px-4 py-2 text-white font-bold rounded bg-latamex-green-500 hover:bg-latamex-green-600" onClick={handleCloseTab}>
                        Cerrar
                    </button> */}
                </div>
            )
            }
            {
                error && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded shadow-lg">
                            <p className="text-lg font-bold mb-2">Ups!</p>
                            {customErrorMessage ? (<p>{customErrorMessage}</p>) : (<p>Por favor revisa la información e intenta nuevamente.</p>)}
                            < button className="mt-4 px-4 py-2 text-white font-bold rounded bg-latamex-green-500 hover:bg-latamex-green-600" onClick={handleClose}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default VibrantCodeValidator;