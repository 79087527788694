import React, { useState } from 'react';

const EmailVerificationForm = ({ setIsValidated }) => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [onValidationError, setOnValidationError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [touched, setTouched] = useState({});

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e) => {
        setTouched({ ...touched, email: true });
        setEmail(e.target.value);
        if (touched.email && regex.test(email)) {
            setEmailError('');
        } else {
            setEmailError('Please review your email address');
        }

    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!regex.test(email)) {
            // La función 'test()' verifica si la cadena cumple con la expresión regular
            setEmailError('Please review your email address');
        } else {
            // Send verification code to the user's email
            setIsLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}getemailcode`, {
                    method: "POST",
                    body: JSON.stringify({ email }),
                }
                )

                if (response.ok) {
                    setIsCodeSent(true);
                } else {
                    console.error('Error:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }

        }
    };

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        if (!verificationCode) {
            setCodeError('Please enter the verification code');
        } else {
            // Validate the verification code

            setIsLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}emailverifcode`, {
                    method: "POST",
                    body: JSON.stringify({ verificationCode }),
                }
                )

                if (response.ok) {
                    setIsValidated(true);
                } else {
                    console.error('Error:', response);
                    setOnValidationError('Invalid verification code')
                }
            } catch (error) {
                console.error('Error:', error);
                setOnValidationError('Something went wrong. Please try again later.')
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-200">
            <div className="max-w-sm w-full min-h-screen sm:min-h-fit p-6 bg-white rounded shadow-lg">
                {/* <h1 className="text-3xl font-bold mb-4">Email Verification</h1> */}
                {!isCodeSent ? (
                    <form className="space-y-6" onSubmit={handleEmailSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-gray-800 font-bold mb-2">
                                Email
                            </label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleEmailChange}
                                className={`w-full p-2 border ${emailError !== '' ? 'border-red-500' : 'border-gray-300'} rounded`}
                                placeholder="Enter your email address"
                                disabled={isLoading}
                            />
                            <span className="text-red-500 text-sm">{emailError}</span>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 flex justify-center items-center text-white font-bold rounded bg-blue-500 hover:bg-blue-600"
                            >
                                {isLoading ? <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg> : 'Send Verification Code'}
                            </button>
                        </div>
                    </form>
                ) : (
                    <form className="space-y-6" onSubmit={handleVerificationSubmit}>
                        <div>
                            <label htmlFor="verificationCode" className="block text-gray-800 font-bold mb-2">
                                Verification Code
                            </label>
                            <input
                                type="text"
                                id="verificationCode"
                                name="verificationCode"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                className={`w-full p-2 border ${codeError !== '' ? 'border-red-500' : 'border-gray-300'} rounded`}
                                placeholder="Enter the verification code"
                                maxLength={4}
                                disabled={isLoading}
                            />
                            <span className="text-red-500 text-sm">{codeError}{onValidationError}</span>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 flex justify-center items-center text-white font-bold rounded bg-blue-500 hover:bg-blue-600"
                            >
                                {isLoading ? <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg> : 'Verify Code'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default EmailVerificationForm;